import React from "react";
import { Container } from "react-bootstrap";
import * as styles from "./Masthead.module.scss";
import MastheadOverlay from "./MastheadOverlay/MastheadOverlay";

const Masthead = (props) => {
  let bgImage = props.image
    ? { backgroundImage: "url(" + props.image + ")" }
    : {};
  return (
    <>
      <div
        style={bgImage}
        className={
          props.overlay
            ? `${styles.root}`
            : props.small
            ? `${styles.rootSmall}`
            : `${styles.rootBasic}`
        }
      >
        {props.image && <div className={styles.imageOverlay} />}
        <Container className={styles.container} fluid>
          {props.children}
        </Container>
      </div>
      {props.overlay && <MastheadOverlay />}
    </>
  );
};

export default Masthead;
