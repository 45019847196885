import React from "react";
import vidWebm from "./masthead-vid.webm";
import vidMp4 from "./masthead-vid.mp4";
import placeHolder from "./screenshot.png";
import * as styles from "./MastheadOverlay.module.scss";
import ReactPlayer from "react-player";

const MastheadOverlay = () => {
  return (
    <section className={styles.root}>
      <div className={styles.box}>
        <div
          data-sal='slide-up'
          data-sal-duration='500'
          data-sal-easing='ease'
          className={styles.vidBox}
        >
          <ReactPlayer
            muted={true}
            playing={true}
            loop={true}
            width='calc(100% + 2px)'
            height='100%'
            url={[
              { src: vidWebm, type: "video/webm" },
              { src: vidMp4, type: "video/mp4" },
            ]}
            config={{
              file: {
                attributes: {
                  poster: placeHolder,
                },
              },
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default MastheadOverlay;
